import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InitialSeatmapState} from './seatmap.state';
import {
  FormMissingBlock,
  FormMissingSection,
  SvgMissingBlock,
  SvgMissingSection,
} from '../types';
import {ISeatmapParsedSection} from '../../../../services/eventsApi';
import {slugify} from '../../../../components/EventsManagement/Seatmap/helpers';
import {toUpper} from 'lodash';

export const seatmapSlice = createSlice({
  name: 'seatmap',
  initialState: InitialSeatmapState,
  reducers: {
    setIsProcessingAction: (state, action: PayloadAction<boolean>) => {
      state.isProcessingAction = action.payload;
    },
    reset: state => {
      state.formMissingBlocks = {};
      state.formMissingSections = [];
      state.svgMissingBlocks = {};
      state.svgMissingSections = [];
      state.errorElementRefsMap = {};
    },
    addErrorElementRef: (
      state,
      action: PayloadAction<{name: string; elementId: HTMLElement}>
    ) => {
      // @ts-ignore
      state.errorElementRefsMap[action.payload.name] = action.payload.element;
    },
    addFormMissingBlock: (
      state,
      action: PayloadAction<{slug: string; blocks: FormMissingBlock[]}>
    ) => {
      state.formMissingBlocks = {
        ...state.formMissingBlocks,
        [action.payload.slug]: action.payload.blocks,
      };
    },
    deleteFormMissingBlocks: (state, action: PayloadAction<{slug: string}>) => {
      delete state.formMissingBlocks[action.payload.slug];
    },
    clearFormMissingBlock: state => {
      state.formMissingBlocks = {};
    },
    addFormMissingSection: (
      state,
      action: PayloadAction<FormMissingSection>
    ) => {
      state.formMissingSections.push(action.payload);
    },
    deleteFormMissingSection: (
      state,
      action: PayloadAction<{sectionName: string}>
    ) => {
      state.formMissingSections = state.formMissingSections.filter(
        section => section.name !== action.payload.sectionName
      );
    },
    clearFormMissingSection: state => {
      state.formMissingSections = [];
    },
    addSvgMissingBlock: (
      state,
      action: PayloadAction<{slug: string; blocks: SvgMissingBlock[]}>
    ) => {
      state.svgMissingBlocks[action.payload.slug] = action.payload.blocks;
    },
    deleteSvgMissingBlocks: (state, action: PayloadAction<{slug: string}>) => {
      delete state.svgMissingBlocks[action.payload.slug];
    },
    deleteSvgMissingBlock: (
      state,
      action: PayloadAction<{sectionSlug: string; blockSlug: string}>
    ) => {
      const svgMissingBlocks = state.svgMissingBlocks;
      const {sectionSlug, blockSlug} = action.payload;
      const sectionBlocks = svgMissingBlocks[sectionSlug];
      if (!sectionBlocks) return;
      const newBlocks = sectionBlocks.filter(block => {
        return block.slug !== blockSlug;
      });

      if (newBlocks.length) {
        svgMissingBlocks[sectionSlug] = newBlocks;
      } else {
        delete svgMissingBlocks[sectionSlug];
      }
    },
    clearSvgMissingBlock: state => {
      state.svgMissingBlocks = {};
    },
    addSvgMissingSection: (state, action: PayloadAction<SvgMissingSection>) => {
      state.svgMissingSections.push(action.payload);
    },
    deleteSvgMissingSection: (
      state,
      action: PayloadAction<{sectionName: string}>
    ) => {
      state.svgMissingSections = state.svgMissingSections.filter(
        section => section.name !== action.payload.sectionName
      );
    },
    deleteSvgMissingSectionById: (
      state,
      action: PayloadAction<number | string>
    ) => {
      state.svgMissingSections = state.svgMissingSections.filter(
        section => String(section.id) !== String(action.payload)
      );
    },
    clearSvgMissingSection: state => {
      state.svgMissingSections = [];
    },
    onSvgParsed: (
      state,
      action: PayloadAction<{
        parsedSections: ISeatmapParsedSection[];
        formSections: FormMissingSection[];
      }>
    ) => {
      const {parsedSections, formSections} = action.payload;

      const svgSections = parsedSections.map(({blocks, ...section}) => ({
        ...slugify(section),
        blocks: blocks
          .map(slugify)
          .map(block => ({...block, name: toUpper(block.name)})),
      }));

      if (!svgSections) return;
      const svgSectionsMap = new Map(
        svgSections.map(section => [section.slug, section])
      );

      const formSectionsMap = new Map(
        formSections.map(section => {
          const slugifiedSection = slugify(section);
          return [String(slugifiedSection.slug), slugifiedSection];
        })
      );

      const newSvgMissingSections = [];
      const newSvgMissingBlocks = [];
      const newFormMissingSections = [];
      const newFormMissingBlocks = [];

      for (const [slug, svgSection] of Array.from(svgSectionsMap.entries())) {
        const formSection = formSectionsMap.get(slug);
        if (formSection) {
          const {svgSectionMissingBlocks, sectionNewParsedBlocks} =
            getParsedAndMissingSectionBlocks(svgSection, formSection);
          if (svgSectionMissingBlocks.length) {
            newSvgMissingBlocks.push({slug, blocks: svgSectionMissingBlocks});
          }

          if (sectionNewParsedBlocks.length) {
            newFormMissingBlocks.push({slug, blocks: sectionNewParsedBlocks});
          }
        } else {
          newFormMissingSections.push(svgSection);
        }
      }

      for (const [slug, formSection] of Array.from(formSectionsMap.entries())) {
        const svgSection = svgSectionsMap.get(slug);
        if (svgSection) {
          const {svgSectionMissingBlocks, sectionNewParsedBlocks} =
            getParsedAndMissingSectionBlocks(svgSection, formSection);
          if (svgSectionMissingBlocks.length) {
            newSvgMissingBlocks.push({slug, blocks: svgSectionMissingBlocks});
          }

          if (sectionNewParsedBlocks.length) {
            newFormMissingBlocks.push({slug, blocks: sectionNewParsedBlocks});
          }
        } else {
          newSvgMissingSections.push(formSection);
        }
      }

      state.formMissingSections = newFormMissingSections;
      state.formMissingBlocks = newFormMissingBlocks.reduce(
        (acc, {slug, blocks}) => ({...acc, [slug]: blocks}),
        {}
      );
      state.svgMissingSections = newSvgMissingSections;
      state.svgMissingBlocks = newSvgMissingBlocks.reduce(
        (acc, {slug, blocks}) => ({...acc, [slug]: blocks}),
        {}
      );
    },
  },
});

const getParsedAndMissingSectionBlocks = (
  svgSection: FormMissingSection,
  uiSection: SvgMissingSection
) => {
  const svgBlocksMap = new Map(
    svgSection.blocks.map(block => {
      const slugifiedBlock = slugify(block);
      return [slugifiedBlock.slug, slugifiedBlock];
    })
  );

  const uiBlocksMap = new Map(
    uiSection.blocks?.map(block => {
      const slugifiedBlock = slugify(block);
      return [slugifiedBlock.slug, slugifiedBlock];
    })
  );

  const sectionNewParsedBlocks = Array.from(svgBlocksMap.entries())
    .filter(([slug]) => !uiBlocksMap.has(slug))
    .map(([, block]) => block);

  const svgSectionMissingBlocks = Array.from(uiBlocksMap.entries())
    .filter(([slug]) => !svgBlocksMap.has(slug))
    .map(([, block]) => block);

  return {sectionNewParsedBlocks, svgSectionMissingBlocks};
};
