import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SearchTextField from '../../Common/SearchTextField';
import Button from '../../Common/Button';
import NewRolePopup from './NewRolePopup';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth/withWidth';
import {useDispatch, useSelector} from 'react-redux';
import {
  API_PERMISSION_LIST_REQUESTED,
  API_ROLE_CREATE_REQUESTED,
  API_ROLE_DELETE_REQUESTED,
  API_ROLE_LIST_REQUESTED,
} from '../../../constants/actionTypes';
import RoleHeader from './RoleHeader';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import {getFiltersFromUrl, setFiltersOnUrl} from '../../../utils/filters';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {
  IPermission,
  IRole,
  useEditEventRolePermissionsMutation,
  useGetEventPermissionsQuery,
  useGetEventRolesQuery,
} from '../../../services/eventsApi';
import {PermissionGroupList} from './PermissionGroupList';
import {OverlaySpinner} from '../../Widgets/Spinner';
import {useEditRolePermissionsMutation} from '../../../services/roleApi';
import {Permission} from '../types';
import IconButton from '../../Common/IconButton';
import {PlusRoundedDangerIcon} from '../../../constants/images';

const PermissionSettings = (props: {width: Breakpoint}) => {
  const {width} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const roleReducer = useSelector((store: any) => store.roleReducer);
  const roles = roleReducer?.items;
  const permissions = useSelector(
    (store: any) => store.permissionReducer.items
  );
  //new role popup state
  const [popupOpen, setPopupOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<any>();
  const [searchTypingTimeout, setSearchTypingTimeout] = useState(0);
  const [filters, setFilters] = useState<Record<string, string>>({q: ''});
  const [rolePermissionsMap, setRolePermissionsMap] = useState<
    Map<string, Array<Permission | IPermission>>
  >(new Map());

  const [eventsRolePermissionsMap, setEventsRolePermissionsMap] = useState<
    Map<string, Array<Permission | IPermission>>
  >(new Map());

  const {data: eventPermissions} = useGetEventPermissionsQuery(filters?.q);
  const {data: eventRoles = []} = useGetEventRolesQuery();
  const [editEventRolePermissions, {isLoading: isEditingEventPermissions}] =
    useEditEventRolePermissionsMutation();
  const [editRolePermissions, {isLoading: isEditingPermissions}] =
    useEditRolePermissionsMutation();

  useEffect(() => {
    dispatch({
      type: API_ROLE_LIST_REQUESTED,
    });
    const permissionFilters = getFiltersFromUrl(['q']);
    setFilters(permissionFilters);
    listPermissions(permissionFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newRolePermissionMap = new Map<string, IPermission[]>();
    for (const role of roles) {
      newRolePermissionMap.set(role.slug, role.permissions);
    }
    setRolePermissionsMap(newRolePermissionMap);
  }, [roles]);

  useEffect(() => {
    const newRolePermissionMap = new Map<string, IPermission[]>();
    for (const role of eventRoles) {
      newRolePermissionMap.set(role.slug, role.permissions);
    }
    setEventsRolePermissionsMap(newRolePermissionMap);
  }, [eventRoles]);

  useEffect(() => {
    // handle create role success
    if (roleReducer.success) {
      setPopupOpen(false);
    }
  }, [roleReducer]);

  //Toggle new role popup state
  const togglePopup = () => setPopupOpen(!popupOpen);

  const updateFilters = (value: any, dispatch = true) => {
    const newFilters = {...filters, ...value};
    setFilters(newFilters);
    if (dispatch) {
      setFiltersOnUrl(newFilters);
      listPermissions(newFilters);
    }
  };

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    updateFilters({q: value}, false);
    //prevent this request when user still typing
    if (searchTypingTimeout) {
      clearTimeout(searchTypingTimeout);
    }
    const searchTimeoutId = setTimeout(() => {
      updateFilters({q: value});
    }, 500) as unknown as number;

    setSearchTypingTimeout(searchTimeoutId);
  };

  const listPermissions = (payload: Record<string, string>) => {
    dispatch({
      type: API_PERMISSION_LIST_REQUESTED,
      payload: payload,
    });
  };

  const handleSaveRole = (values: any) => {
    dispatch({
      type: API_ROLE_CREATE_REQUESTED,
      payload: values,
    });
  };

  const handleEditRole = async (
    role: IRole,
    values: {permissions?: Array<Permission | IPermission>; name?: string}
  ) => {
    const {permissions, name} = values;
    const payload: {permissions?: number[]; name?: string} = {};
    if (name) {
      payload.name = name;
    }

    if (permissions) {
      payload.permissions = permissions
        .filter(permission => 'permission_group' in permission)
        .map(permission => permission.id);
    }

    const result = await editRolePermissions({
      id: role.id,
      payload,
    });
    if ('data' in result) return result.data.success;
    return false;
  };

  const handleEditEventRolePermissions = async (
    role: IRole,
    permissions: Array<Permission | IPermission>,
    isEventPermission?: boolean
  ) => {
    const filteredPermissionIds = permissions
      .filter(permission => 'permissionGroup' in permission)
      .map(permission => permission.id);
    const result = await editEventRolePermissions({
      slug: role.slug,
      permissions: filteredPermissionIds,
    });
    if ('data' in result) return !!result.data;
    return false;
  };

  const handleDeleteRole = () => {
    dispatch({
      type: API_ROLE_DELETE_REQUESTED,
      payload: {id: deleteConfirmation?.id},
    });
    setDeleteConfirmation(null);
  };

  const filteredEventRoles = useMemo(() => {
    const roleSlugMap = new Map<string, IRole>();
    roles.forEach((role: IRole) => {
      roleSlugMap.set(role.slug, {...role, permissions: []});
    });
    eventRoles.forEach((role: IRole) => {
      if (roleSlugMap.has(role.slug)) {
        roleSlugMap.set(role.slug, role);
      }
    });

    return Array.from(roleSlugMap.values());
  }, [eventRoles, roles]);

  const columnWidth = 100 / roles.length;

  return (
    <Fragment>
      {/*Confirmation popup*/}
      <OverlaySpinner
        isLoading={isEditingEventPermissions || isEditingPermissions}
      />
      <DeleteConfirmationModal
        opened={!!deleteConfirmation}
        onAccept={handleDeleteRole}
        onClose={() => setDeleteConfirmation(null)}
      />
      {/*New Role popup*/}
      <NewRolePopup
        opened={popupOpen}
        onClose={togglePopup}
        saveRole={handleSaveRole}
      />
      {/*See permission management*/}
      <div className="details-page pb-3 pb-lg-0">
        <div className="container-fluid permission-management px-lg-0">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="top-column-left">
                <Typography
                  variant="h3"
                  className="py-3 font-weight-bolder text-center text-lg-start"
                >
                  {intl.formatMessage({
                    id: 'dashboard.settings.permissions.permission_management',
                  })}
                </Typography>
                {/*Keyword search*/}
                <div className="pt-lg-3">
                  <div className="d-flex align-items-center gap-1">
                    <SearchTextField
                      clearable
                      // @ts-ignore
                      buttonProps={{
                        onClear: () => updateFilters({q: ''}),
                      }}
                      value={filters.q || ''}
                      onSearchChanged={onSearchChange}
                      placeholder={intl.formatMessage({id: 'actions.search'})}
                      grayBackground={isWidthUp('lg', width)}
                    />
                    <IconButton
                      size="sm"
                      variant="filled"
                      className="d-lg-none"
                      onClick={togglePopup}
                    >
                      <PlusRoundedDangerIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 text-end">
              <div className="top-column-right d-none d-lg-block">
                {/*New Role button*/}
                <Button
                  primary
                  title={intl.formatMessage({
                    id: 'dashboard.settings.permissions.new_role',
                  })}
                  // @ts-ignore
                  icon={<AddIcon />}
                  onClick={togglePopup}
                />
              </div>
            </div>
          </div>
          {/*Role list*/}
          <div className="row gx-0">
            <div className="col-lg-4 bg-white border-bottom"></div>
            <div className="col-lg-8">
              <div className="role-list">
                {roles.map((role: any) => (
                  <RoleHeader
                    key={role.id}
                    style={{width: `${columnWidth}%`}}
                    role={role}
                    onUpdateRoleName={(role: any, value: any) =>
                      handleEditRole(role, {name: value})
                    }
                    onDeleteRole={(role: any) => setDeleteConfirmation(role)}
                  />
                ))}
              </div>
            </div>
          </div>

          {Object.keys(permissions).map((groupTitle, index) => (
            <PermissionGroupList
              key={groupTitle}
              title={groupTitle}
              permissions={permissions[groupTitle]}
              roles={roles}
              onPermissionAssigned={(role, permissions) =>
                handleEditRole(role, {permissions})
              }
              rolePermissionsMap={rolePermissionsMap}
              setRolePermissionsMap={setRolePermissionsMap}
            />
          ))}
          {eventPermissions &&
            Object.entries(eventPermissions).map(
              ([groupTitle, rolePermissions]) => (
                <PermissionGroupList
                  key={groupTitle}
                  title={groupTitle}
                  permissions={rolePermissions}
                  roles={filteredEventRoles}
                  onPermissionAssigned={handleEditEventRolePermissions}
                  rolePermissionsMap={eventsRolePermissionsMap}
                  setRolePermissionsMap={setEventsRolePermissionsMap}
                />
              )
            )}
        </div>
      </div>
    </Fragment>
  );
};

export default withWidth()(
  // @ts-ignore
  PermissionSettings
);
