import {Card, CardBody, Col, Row} from 'reactstrap';
import InputField from '../../Form/Fields/InputField';
import SeatmapList from './SeatmapList';
import {useIntl} from 'react-intl';
import {usePlacesWidget} from 'react-google-autocomplete';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as mapsAutocompleteHelper from './helpers';
import {IAutocompleteResult} from './helpers';
import {Controller, UseFormReturn} from 'react-hook-form';
import {VenueFormFields} from './types';
import {ISeatmap} from '../../../services/eventsApi';
import {EnhancedInputField} from '../../Form/Fields/EnhancedInputField/EnhancedInputField';

interface VenueDetailsFormProps {
  form: UseFormReturn<VenueFormFields>;
  onSubmit: (data: VenueFormFields) => void;
  defaultValues?: {
    addressLine1?: string;
  };
  isFormDisabled?: boolean;
  seatmaps?: ISeatmap[];
}

const VenueForm = (props: VenueDetailsFormProps) => {
  const {form, onSubmit, defaultValues, isFormDisabled, seatmaps} = props;
  const intl = useIntl();

  const {
    setValue,
    watch,
    clearErrors,
    formState: {errors},
    register,
    control,
    trigger,
    getValues,
  } = form;

  const onPlaceSelected = useCallback(
    (place: IAutocompleteResult, ref) => {
      if (!place.address_components) return;
      const latLng = mapsAutocompleteHelper.getLatLng(place);
      const city = mapsAutocompleteHelper.getCity(place);
      const country = mapsAutocompleteHelper.getCounty(place);
      clearErrors('addressLine1');
      setValue('addressLine1', ref.value);
      void trigger('addressLine1');
      setValue('latitude', latLng?.lat);
      setValue('longitude', latLng?.lng);
      setValue('city', city ?? '');
      setValue('country', country ?? '');
    },
    [clearErrors, setValue, trigger]
  );

  const {ref} = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ['address'],
    },
  });

  const addressLine2 = watch('addressLine2');

  useEffect(() => {
    if (defaultValues?.addressLine1 && ref.current) {
      ref.current.value = defaultValues?.addressLine1;
    }
  }, [defaultValues, ref]);

  const onAddressBlur = useCallback(() => {
    setTimeout(() => {
      const city = getValues('city');
      if (!city) {
        form.setError('addressLine1', {
          type: 'manual',
          message: intl.formatMessage({
            id: 'validation.error.select_from_suggestions',
          }),
        });
      } else {
        clearErrors('addressLine1');
      }
    }, 200);
  }, [clearErrors, form, getValues, intl]);
  return (
    <form onSubmit={form.handleSubmit(onSubmit)} data-testid="venue-form">
      <Row>
        <Col xl={8}>
          <Card className="details-card">
            <CardBody>
              <EnhancedInputField
                label={`${intl.formatMessage({
                  id: 'dashboard.events.venue_details.venue_name',
                })}*`}
                disabled={isFormDisabled}
                error={!!errors.name?.message}
                errorMessage={errors.name?.message}
                wrapperProps={{
                  className: 'form-group mb-2 mb-lg-fg',
                  'data-testid': 'venue-name-input-wrapper',
                }}
                {...register('name')}
                onChange={e => {
                  void register('name').onChange(e);
                  clearErrors('name');
                }}
              />
              <Controller
                control={control}
                name="addressLine1"
                render={({
                  formState: {
                    errors: {addressLine1: addressLine1Error},
                  },
                }) => (
                  <InputField
                    ignoreDispatch
                    outlined
                    label={`${intl.formatMessage({
                      id: 'dashboard.events.venue_details.venue_address_line1',
                    })}*`}
                    externalLabel
                    formGroupClass="form-group mb-2 mb-lg-fg"
                    autoComplete="off"
                    ref={ref}
                    input={{
                      onBlur: onAddressBlur,
                      onChange: (e: any) => {
                        setValue('addressLine1', e.target.value);
                        setValue('city', '');
                        clearErrors('addressLine1');
                      },
                    }}
                    error={!!addressLine1Error}
                    disabled={isFormDisabled}
                    meta={{
                      error: errors.addressLine1?.message,
                      touched: true,
                    }}
                    errorHelperText={!!addressLine1Error?.message}
                    helperText={addressLine1Error?.message}
                    wrapperProps={{
                      'data-testid': 'venue-autocomplete-input-wrapper',
                    }}
                  />
                )}
              />

              <InputField
                ignoreDispatch
                outlined
                label={`${intl.formatMessage({
                  id: 'dashboard.events.venue_details.venue_address_line2',
                })}`}
                externalLabel
                formGroupClass="form-group mb-0"
                disabled={isFormDisabled}
                input={{
                  onChange: (event: ChangeEvent<HTMLInputElement> | string) => {
                    if (typeof event === 'string') return;
                    setValue('addressLine2', event.target.value);
                    clearErrors('addressLine2');
                  },
                  value: addressLine2,
                }}
                meta={{
                  error: errors.addressLine2?.message,
                  touched: !!errors.addressLine2,
                }}
                wrapperProps={{
                  'data-testid': 'venue-addressLine2-input-wrapper',
                }}
              />
              {seatmaps && seatmaps.length > 0 && (
                <SeatmapList className="mt-2 mt-lg-fg" data={seatmaps} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default VenueForm;
